import axios from "axios";
//const API_URL = "http://localhost:8080";
const API_URL = "https://api.fullpass.com.ar";

const _fetch = async (endpoint, onSuccess, onFailure, body, method = "get", needAuth = true) => {
  const token = (needAuth && JSON.parse(localStorage.getItem("fullpass_user"))?.token) || "";
  const headers = {
    "Content-Type": "application/json",
    Authorization: token,
  };
  const options = {
    method,
    headers,
    url: `${API_URL}${endpoint}`,
    data: body,
  };

  try {
    const response = await axios(options);
    if (response.status >= 200 && response.status < 300) {
      onSuccess(response.data);
    } else {
      if (response.status == 406) {
        localStorage.removeItem("fullpass_user");
        window.location.reload();
      } else {
        onFailure(response);
      }
    }
  } catch (error) {
    if (error.response?.status == 406) {
      localStorage.removeItem("fullpass_user");
      window.location.reload();
    } else {
      onFailure(error.response || error);
    }
  }
};

const getGyms = (onSuccess, onFailure) => {
  _fetch(`/gym`, onSuccess, onFailure, null, "get", true);
};

const api = {
  getGyms,
};

export default api;
