import { Box, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import FaqDropDown from "../inputs/FAQComponent";
import { useEffect, useState } from "react";

const FAQ = ({ setSectionInView, mobile }) => {
  const { ref, inView } = useInView({ triggerOnce: false, threshold: 0.3 });
  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    if (inView) {
      setSectionInView("faq");
      setAnimate(true);
    }
  }, [inView]);

  const faqItems = [
    {
      text: "¿Cómo hago para acceder al gimnasio?",
      value:
        "En la app de Full Pass, buscás el gimnasio que querés acceder, y realizas la reserva para la hora que quieras. Tenés un margen de +/- 30 minutos para ingresar.",
    },
    {
      text: "¿Puedo realizar más de una reserva en el día?",
      value: "No, solo se puede realizar una reserva por día.",
    },
    {
      text: "¿Puedo cancelar la reserva sin penalidad?",
      value: "Sí, podés cancelar la reserva en cualquier momento.",
    },
    {
      text: "¿Cómo solicito la baja de la suscripción?",
      value: "En la app de Full Pass, ingresá a tu Perfil -> Gestionar Membresía -> Dar de baja la suscripción.",
    },
  ];

  return (
    <Box
      ref={ref}
      name="faq"
      style={{
        width: "100%",
        backgroundColor: "#181A20",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
        gap: "20px",
        paddingBottom: "50px",
        zIndex: 2,
      }}
    >
      <Typography
        style={{
          color: "white",
          fontSize: mobile ? "36px" : "50px",
          textAlign: "center",
          paddingBottom: "20px",
        }}
      >
        Preguntas frecuentes
      </Typography>
      <Box
        style={{
          width: "100%",
          maxWidth: "90%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
        }}
      >
        {faqItems.map((item, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 30 }}
            animate={animate ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.75, delay: index * 0.3 }}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FaqDropDown text={item.text} value={item.value} />
          </motion.div>
        ))}
      </Box>
    </Box>
  );
};

export default FAQ;
