import { Box, Typography } from "@mui/material";
import Button from "../inputs/Button";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";
import { scroller } from "react-scroll";
import { motion } from "framer-motion";

const Price = ({ setSectionInView, mobile }) => {
  const { ref, inView } = useInView({ triggerOnce: false, threshold: 0.5 });
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (inView) {
      setSectionInView("price");
      setAnimate(true);
    }
  }, [inView]);

  const scrollTo = (element) => {
    scroller.scrollTo(element, {
      duration: 800,
      delay: 0,
      smooth: true,
    });
  };

  useEffect(() => {
    if (inView) {
      setSectionInView("price");
    }
  }, [inView]);
  return (
    <Box
      ref={ref}
      name="price"
      style={{
        minWidth: "100%",
        minHeight: mobile ? "80vh" : "100svh",
        background: "linear-gradient(180deg, #000000, #0B0B0E)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        zIndex: 2,
        paddingBottom: mobile ? "5px" : "0px",
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: 30 }}
        animate={animate ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.75, delay: 1 * 0.3 }}
      >
        <Typography
          style={{ color: "white", fontSize: mobile ? "32px" : "50px", textAlign: "center", padding: "0px 20px" }}
        >
          <strong>Gym</strong>. <strong>Crossfit</strong>. <strong>Pádel</strong>. <strong>Fútbol</strong>.{" "}
          <strong>Natación</strong>.
        </Typography>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 30 }}
        animate={animate ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.75, delay: 2 * 0.3 }}
      >
        <Typography
          style={{ color: "white", fontSize: mobile ? "30px" : "50px", textAlign: "center", padding: "0px 10px" }}
        >
          Todo esto y más, por el precio de <strong>uno</strong>.
        </Typography>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 30 }}
        animate={animate ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.75, delay: 3 * 0.3 }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            border: "3px solid #FF9700",
            borderRadius: "10px",
            maxWidth: "400px",
            margin: mobile ? "30px 30px 20px 30px" : "100px 20px 20px 20px",
          }}
        >
          <Box
            style={{
              backgroundColor: "#FF9700",
              width: "100%",
              borderRadius: "10px",
              padding: "3px",
            }}
          >
            <Typography style={{ color: "white", fontSize: "30px", textAlign: "center" }}>
              <strong>Membresía Full</strong>
            </Typography>
          </Box>

          <Typography
            style={{
              color: "white",
              fontSize: mobile ? "24px" : "30px",
              textAlign: "center",
              padding: "10px 20px 0px 20px",
            }}
          >
            Acceso a <strong>todos</strong> los gimnasios de la red <strong>Full Pass</strong>.
          </Typography>
          <Typography style={{ color: "white", fontSize: mobile ? "32px" : "44px", textAlign: "center" }}>
            <strong>$29.990</strong> / mes
          </Typography>
        </Box>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 30 }}
        animate={animate ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.75, delay: 4 * 0.3 }}
        style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            width: "100%",
            maxWidth: "calc(100% - 60px)",
          }}
        >
          <Button stroked onClick={() => scrollTo("gyms")}>
            Ver gimnasios y actividades
          </Button>
          <Button onClick={() => window.location.assign("https://app.fullpass.com.ar/")}>Registrarme</Button>
        </Box>
      </motion.div>
    </Box>
  );
};

export default Price;
