import { Box, Typography } from "@mui/material";
import { useWindowSize } from "react-use";
import { Link } from "react-scroll";
import Home from "./sections/Home";
import Price from "./sections/Price";
import Gyms from "./sections/Gyms";
import FAQ from "./sections/FAQ";
import Contact from "./sections/Contact";

const Container = ({ setSectionInView, mobile, imageLoaded, setImageLoaded }) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
        height: "100%",
        width: "100%",
        /*scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },*/
      }}
    >
      <Home
        setSectionInView={setSectionInView}
        mobile={mobile}
        imageLoaded={imageLoaded}
        setImageLoaded={setImageLoaded}
      />
      <Price setSectionInView={setSectionInView} mobile={mobile} />
      <Gyms setSectionInView={setSectionInView} mobile={mobile} />
      <FAQ setSectionInView={setSectionInView} mobile={mobile} />
      <Contact setSectionInView={setSectionInView} mobile={mobile} />
    </Box>
  );
};

export default Container;
