import { Box, ButtonBase, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

const FaqDropDown = ({ text, value, onClick, onOptionClicked, stroked, buttonOptions }) => {
  const [opened, setOpened] = useState(false);

  const OptionComponent = buttonOptions ? ButtonBase : Box;

  return (
    <>
      <Box style={{ width: "100%", maxWidth: "800px" }}>
        <ButtonBase
          style={{
            width: "100%",
            minHeight: "50px",
            height: "auto",
            background: stroked ? "rgba(0,0,0,0)" : "linear-gradient(225deg, #FF9700, #FF6401)",
            borderRadius: "10px",
            justifyContent: "space-between",
            padding: "10px 20px",
            zIndex: 1,
            border: stroked ? "2px solid #FF6401" : "none",
          }}
          onClick={() => {
            setOpened(!opened);
          }}
        >
          <Typography
            style={{
              fontWeight: "500",
              fontSize: "18px",
              color: stroked ? "#FF9700" : "white",
              textAlign: "left",
            }}
          >
            {text}
          </Typography>
          {opened ? (
            <ArrowDropUpIcon
              style={{
                color: stroked ? "#FF9700" : "white",
              }}
            />
          ) : (
            <ArrowDropDownIcon
              style={{
                color: stroked ? "#FF9700" : "white",
              }}
            />
          )}
        </ButtonBase>
        {opened && value && (
          <Box
            style={{
              border: "2px solid #FF6401",
              borderRadius: "10px",
              position: "relative",
              marginTop: "-50px",
              paddingTop: "50px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <OptionComponent
              style={{
                width: "100%",
                background: "rgba(0,0,0,0)",
                justifyContent: "center",
                zIndex: 1,
                border: "none",
              }}
            >
              <Typography
                style={{
                  fontWeight: "500",
                  fontSize: "18px",
                  color: "white",
                  textAlign: "left",
                  padding: "10px 20px",
                }}
              >
                {value}
              </Typography>
            </OptionComponent>
          </Box>
        )}
      </Box>
    </>
  );
};

export default FaqDropDown;
