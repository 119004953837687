import logo from "./logo.svg";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import Container from "./components/Container";
import NavBar from "./components/navigation/Navbar";
import { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";

function App() {
  const [mobile, setMobile] = useState(0);
  const [sectionInView, setSectionInView] = useState("home");
  const [imageLoaded, setImageLoaded] = useState(false);

  const theme = createTheme({
    typography: {
      fontFamily: "Urbanist",
    },
  });

  const handleResize = () => {
    if (isMobile && window.screen.orientation.type.includes("portrait")) {
      setMobile(1);
    } else if (window.innerWidth < 700) {
      setMobile(1);
    } else if (isMobile) {
      setMobile(2);
    } else {
      setMobile(0);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <NavBar sectionInView={sectionInView} mobile={mobile} imageLoaded={imageLoaded} />
      <Container
        setSectionInView={setSectionInView}
        mobile={mobile}
        imageLoaded={imageLoaded}
        setImageLoaded={setImageLoaded}
      />
    </ThemeProvider>
  );
}

export default App;
