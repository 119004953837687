import React, { useState, useEffect } from "react";
import Map, { Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Box, ButtonBase } from "@mui/material";

const MAPBOX_TOKEN =
  "pk.eyJ1IjoiZ2VybWFuYnVjaG11bGxlciIsImEiOiJjbTJ4amxkeWEwNWEzMmpxMng4Z295anh6In0.amQu5AzMu14FKvlgQvgd_Q"; // Set your mapbox token here

const CustomMap = ({
  borderRadius,
  gyms,
  initialLat,
  initialLon,
  zoom = 14,
  onMarkerClicked,
  setSelectedGym,
  onMapDragged,
}) => {
  const [viewport, setViewport] = useState({
    latitude: 0,
    longitude: 0,
    zoom,
  });

  const [location, setLocation] = useState(null);
  const [userLocation, setUserLocation] = useState(null);

  /*useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setUserLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      () => null
    );
  }, []);*/

  useEffect(() => {
    if (initialLat && initialLon) {
      setLocation({ latitude: initialLat, longitude: initialLon });
      setViewport((prev) => ({
        ...prev,
        latitude: initialLat,
        longitude: initialLon,
      }));
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setViewport((prev) => ({
            ...prev,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          }));
        },
        () => null
      );
    }
  }, []);

  return (
    <>
      <style>
        {`
          .mapboxgl-ctrl-logo, .mapboxgl-ctrl-attrib {
            display: none !important;
          }
        `}
      </style>
      {location && (
        <Map
          initialViewState={viewport}
          style={{
            width: "100%",
            height: "100%",
            borderRadius,
          }}
          mapStyle="mapbox://styles/mapbox/dark-v11"
          mapboxAccessToken={MAPBOX_TOKEN}
          onViewportChange={(nextViewport) => setViewport(nextViewport)}
          attributionControl={false}
          onDrag={() => {
            onMapDragged && onMapDragged(viewport.latitude, viewport.longitude);
          }}
          scrollZoom={false}
        >
          {userLocation && (
            <Marker latitude={userLocation.latitude} longitude={userLocation.longitude}>
              <Box
                style={{
                  width: "15px",
                  height: "15px",
                  borderRadius: "10px",
                  backgroundColor: "#FF9700",
                }}
              ></Box>
            </Marker>
          )}
          {gyms.map((gym) => (
            <Marker key={gym.id} latitude={gym.lat} longitude={gym.lon}>
              <ButtonBase
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "20px",
                  backgroundColor: "#181A20",
                  padding: "4px",
                }}
                onClick={() => {
                  setSelectedGym && setSelectedGym(gym);
                  onMarkerClicked && onMarkerClicked();
                }}
              >
                <img style={{ width: "100%", height: "100%" }} src={gym.iconLowResUrl} alt="gym_icon" />
              </ButtonBase>
            </Marker>
          ))}
        </Map>
      )}
    </>
  );
};

export default CustomMap;
