import { Box, Typography } from "@mui/material";
import Button from "../inputs/Button";
import { motion } from "framer-motion";
import { scroller } from "react-scroll";

const NavBar = ({ sectionInView, mobile, imageLoaded }) => {
  const scrollTo = (element) => {
    scroller.scrollTo(element, {
      duration: 800,
      delay: 0,
      smooth: true,
    });
  };

  return (
    <>
      {imageLoaded && (
        <motion.div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            height: "56px",
            background: "linear-gradient(0deg, rgba(0,0,0,0), rgba(0,0,0,1))",
            zIndex: 10,
            padding: "10px 20px",
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 2.5 }}
        >
          <img src="img/logo_transparent.png" style={{ width: "105px" }} alt="logo_transparent" />
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "20px",
              padding: "0px 40px",
            }}
          >
            {!mobile && (
              <>
                <Typography
                  style={{
                    color: "white",
                    fontSize: "24px",
                    textAlign: "center",
                    fontWeight: sectionInView === "home" ? "bold" : "normal",
                    cursor: "pointer",
                    minWidth: "57px",
                  }}
                  onMouseEnter={(e) => (e.target.style.fontWeight = "bold")}
                  onMouseLeave={(e) => (e.target.style.fontWeight = sectionInView === "home" ? "bold" : "normal")}
                  onClick={() => scrollTo("home")}
                >
                  Inicio
                </Typography>

                <Typography
                  style={{
                    color: "white",
                    fontSize: "24px",
                    textAlign: "center",
                    fontWeight: sectionInView === "price" ? "bold" : "normal",
                    cursor: "pointer",
                    minWidth: "66px",
                  }}
                  onMouseEnter={(e) => (e.target.style.fontWeight = "bold")}
                  onMouseLeave={(e) => (e.target.style.fontWeight = sectionInView === "price" ? "bold" : "normal")}
                  onClick={() => scrollTo("price")}
                >
                  Precio
                </Typography>
                <Typography
                  style={{
                    color: "white",
                    fontSize: "24px",
                    textAlign: "center",
                    fontWeight: sectionInView === "gyms" ? "bold" : "normal",
                    cursor: "pointer",
                    minWidth: "113px",
                  }}
                  onMouseEnter={(e) => (e.target.style.fontWeight = "bold")}
                  onMouseLeave={(e) => (e.target.style.fontWeight = sectionInView === "gyms" ? "bold" : "normal")}
                  onClick={() => scrollTo("gyms")}
                >
                  Gimnasios
                </Typography>
                <Typography
                  style={{
                    color: "white",
                    fontSize: "24px",
                    textAlign: "center",
                    fontWeight: sectionInView === "faq" ? "bold" : "normal",
                    cursor: "pointer",
                    minWidth: "45px",
                  }}
                  onMouseEnter={(e) => (e.target.style.fontWeight = "bold")}
                  onMouseLeave={(e) => (e.target.style.fontWeight = sectionInView === "faq" ? "bold" : "normal")}
                  onClick={() => scrollTo("faq")}
                >
                  FAQ
                </Typography>
              </>
            )}
            <Button
              stroked
              sx={{ width: "150px", maxHeight: "40px" }}
              onClick={() => window.location.assign("https://app.fullpass.com.ar/")}
            >
              Ingresar
            </Button>
          </Box>
        </motion.div>
      )}
    </>
  );
};

export default NavBar;
