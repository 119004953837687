import React from "react";
import {
  Box,
  Typography,
  Container,
  ButtonBase,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
  LinearProgress,
} from "@mui/material";

const Button = ({ onClick, children, sx, stroked, disabled, loading }) => {
  const [mouseEntered, setMouseEntered] = React.useState(false);
  return (
    <ButtonBase
      onClick={() => {
        setMouseEntered(false);
        onClick();
      }}
      style={{
        background: stroked
          ? !mouseEntered
            ? undefined
            : "linear-gradient(225deg, #FF9700, #FF6401)"
          : loading
          ? "linear-gradient(225deg, #CC7A00, #CC5101)" // Gradiente más oscuro cuando loading es true
          : "linear-gradient(225deg, #FF9700, #FF6401)",
        borderRadius: "10px",
        minHeight: "45px",
        stroke: "white",
        border: stroked ? "2px solid #FF6401" : "none",
        cursor: "pointer",
        padding: "10px 20px",
        width: "100%",
        height: "55px",
        maxWidth: "400px",
        margin: "0 auto",
        opacity: disabled ? 0.5 : 1,
        ...sx,
      }}
      disabled={disabled}
      onMouseEnter={() => setMouseEntered(true)}
      onMouseLeave={() => setMouseEntered(false)}
      onTouchStart={() => setMouseEntered(false)}
    >
      {!loading && (
        <Typography
          style={{
            fontWeight: "500",
            fontSize: "18px",
            color: stroked ? (!mouseEntered ? "#FF6401" : "white") : "white",
            textAlign: "center",
          }}
        >
          {children}
        </Typography>
      )}
      {/*loading && (
        <LinearProgress
          sx={{
            width: "100%",
            backgroundColor: "#181A20", // Color de fondo de la barra vacía
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#FF9700", // Color de la barra de progreso
            },
          }}
        />
      )*/}
      {loading && (
        <CircularProgress
          sx={{
            color: stroked ? "#FF6401" : "white",
            height: "100%", // Ajusta la altura al 100% del botón
            width: "100%", // Ajusta el ancho al 100% del botón
          }}
          size={26}
        />
      )}
    </ButtonBase>
  );
};

export default Button;
