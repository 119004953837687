import { Box, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";
const height = window.innerHeight;
const Home = ({ setSectionInView, mobile, imageLoaded, setImageLoaded }) => {
  const { ref, inView } = useInView({ triggerOnce: false, threshold: 0.5 });

  useEffect(() => {
    if (inView) {
      setSectionInView("home");
    }
  }, [inView]);

  return (
    <Box ref={ref} name="home" style={{ position: "relative", width: "100%", height: `${height}px` }}>
      <img
        src="img/BackgroundImg.png"
        alt="BackgroundImg"
        onLoad={() => setImageLoaded(true)}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 0,
          display: imageLoaded ? "block" : "none",
        }}
      />

      <Box
        style={{
          position: "absolute",
          top: "80%",
          left: 0,
          width: "100%",
          height: "20%",
          background: "linear-gradient(180deg, rgba(0,0,0,0), rgba(0,0,0,1))",
        }}
      />

      {imageLoaded && (
        <>
          <motion.div
            style={{
              color: "white",
              position: "absolute",
              top: mobile ? "85%" : "80%",
              left: "0",
              fontSize: "70px",
              zIndex: 1,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 3, delay: 4 }}
          >
            <motion.div
              animate={{
                y: [0, -30, 0],
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                repeatType: "reverse",
                ease: "easeInOut",
              }}
            >
              <ExpandMoreIcon
                style={{
                  color: "white",
                  fontSize: "70px",
                  zIndex: 1,
                }}
              />
            </motion.div>
          </motion.div>

          <Box
            style={{
              position: "relative",
              zIndex: 1,
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: mobile ? "column" : "row",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: mobile ? "center" : "start",
                padding: "0 15px",
              }}
            >
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5, ease: "easeInOut" }}
              >
                <Typography style={{ color: "white", fontSize: mobile ? "36px" : "50px" }}>
                  Una <strong>suscripción</strong>.
                </Typography>
              </motion.div>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 1.5, ease: "easeOut" }}
              >
                <Typography style={{ color: "white", fontSize: mobile ? "36px" : "50px" }}>
                  <strong>Todos</strong> tus gimnasios.
                </Typography>
              </motion.div>
            </Box>

            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 4, delay: 2.5, type: "spring" }}
              style={{
                width: "40%",
                minWidth: mobile ? "200px" : "300px",
                maxWidth: "450px",
              }}
            >
              <motion.img src="img/fplogo.png" alt="fplogo" style={{ width: "100%" }} />
            </motion.div>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Home;
