import { Box, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import FaqDropDown from "../inputs/FAQComponent";
import { useEffect, useState } from "react";

const FAQ = ({ setSectionInView }) => {
  const { ref, inView } = useInView({ triggerOnce: false, threshold: 0.5 });
  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    if (inView) {
      setSectionInView("faq");
      setAnimate(true);
    }
  }, [inView]);

  return (
    <Box
      ref={ref}
      name="faq"
      style={{
        minWidth: "100%",
        backgroundColor: "#181A20",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
        gap: "20px",
        paddingBottom: "50px",
        zIndex: 2,
      }}
    >
      <Typography
        style={{
          color: "white",
          fontSize: "50px",
          textAlign: "center",
        }}
      >
        Contacto
      </Typography>
      <Typography
        style={{
          color: "white",
          textAlign: "center",
          padding: "20px",
          fontSize: "20px",
        }}
      >
        <strong>Correo electrónico:</strong> contacto@fullpass.com.ar
      </Typography>
    </Box>
  );
};

export default FAQ;
