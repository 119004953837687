import { Box, Typography } from "@mui/material";
import Map from "../layout/Map";
import { useEffect, useState } from "react";
import api from "../api";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

const Gyms = ({ setSectionInView, mobile }) => {
  const { ref, inView } = useInView({ triggerOnce: false, threshold: 0.25 });
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (inView) {
      setSectionInView("gyms");
      setAnimate(true);
    }
  }, [inView]);

  const [gyms, setGyms] = useState([]);

  const getGyms = async () => {
    api.getGyms(
      (res) => {
        setGyms(res);
      },
      (err) => {
        console.error(err);
      }
    );
  };

  useEffect(() => {
    getGyms();
  }, []);

  return (
    <Box
      ref={ref}
      name="gyms"
      style={{
        minWidth: "100%",
        minHeight: mobile ? "50vh" : "100vh",
        background: "linear-gradient(180deg,  #0B0B0E, #181A20)",
        display: "flex",
        flexDirection: "column",
        justifyContent: mobile ? "start" : "space-around",
        alignItems: "center",
        gap: "10px",
        padding: mobile ? "60px 0px" : "0px",
        zIndex: 2,
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: 30 }}
        animate={animate ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.75, delay: 0.3 }}
        style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Typography
          style={{
            color: "white",
            fontSize: mobile ? "32px" : "50px",
            textAlign: "center",
            padding: mobile ? "0px 5px" : "0px 20px",
          }}
        >
          Encontrá dónde va a ser tu próximo <strong>entremaniento</strong>.
        </Typography>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 30 }}
        animate={animate ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.75, delay: 2 * 0.3 }}
        style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box style={{ width: mobile ? "90%" : "80%", height: mobile ? "300px" : "80vh" }}>
          <Map
            borderRadius="10px"
            gyms={gyms}
            initialLat={-34.4111214}
            initialLon={-58.7202636}
            zoom={10}
            onMarkerClicked={() => {}}
            setSelectedGym={() => {}}
            onMapDragged={() => {}}
          />
        </Box>
      </motion.div>
    </Box>
  );
};

export default Gyms;
